.list-item {
  background-color: var(--surface);
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.list-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.list-item .list-title,
.list-item .view-full-list {
  cursor: pointer;
}
.list-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.list-item-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--primary-color);
  height: 30px;
  overflow: hidden;
}
.list-item-actions {
  display: flex;
  gap: 0.5rem;
}
.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--on-surface);
}
.list-item-description {
  margin-bottom: 1rem;
  color: var(--on-surface-medium);
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.list-item-stats {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  color: var(--on-surface-medium);
  align-items: center;
  margin-bottom:5px;
}
.list-item-creator {
  font-size: 0.9rem;
  color: var(--on-surface-medium);
  margin-bottom: 0.5rem;
}
.list-item-preview {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
  padding-bottom: 0.25rem;
  background-color: #1f1d1d; 
  border-radius: 10px;
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.5), 
              inset 0 -4px 6px rgba(255, 255, 255, 0.1);
}
.list-item .more-videos {
  cursor:pointer;
}
.list-item-preview .video-card {
  flex: 0 0 auto;
  width: 120px;
}
.list-item-preview .video-card .channel-link {
  display:none;
}
.list-item-preview .video-card .category-link {
  margin-left:0px;
}
.more-videos {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 67px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  border-radius: 4px;
}
.list-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
.view-full-list {
  color: var(--primary-color);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.like-button {
  cursor: pointer;
  transition: color 0.3s ease;
}
.like-button.liked {
  color: red;
}
.list-actions-dropdown {
  position: relative;
  display: inline-block;
}
.list-actions-dropdown .dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  bottom: 100%;
  background-color: var(--surface);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
}
.list-item-actions button,
.list-actions-dropdown button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--on-surface);
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.list-item-actions button:hover,
.list-actions-dropdown button:hover,
.list-actions-dropdown .dropdown-content button:hover {
  background-color: var(--primary-color);
}
.list-actions-dropdown .dropdown-content button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.list-actions-dropdown:hover .dropdown-content {
  display: block;
}
.edit-list-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.edit-list-modal form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.create-list-button {
  flex-shrink: 0;
  width: auto;
}
.create-list-form {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-bottom: 20px;
  max-width: 45%;
}
.create-list-form input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  max-width: 200px;
}
.create-list-form button {
  white-space: nowrap;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: var(--on-primary);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 150px;
}
.create-list-form button:hover {
  background-color: var(--primary-variant);
}
.list-browsing-page .list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
.add-to-list-dropdown {
  position: relative;
}
.dropdown-content {
  position: absolute;
  background-color: var(--surface);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 10px;
  z-index: 1001;
  min-width: 150px;
  overflow: visible;
}
.dropdown-list-item{
  padding:5px;
}
.dropdown-list-item:hover {
  transform: scale(1.02);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  background-color: var(--secondary-color);
}
.add-to-list-dropdown .list-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.add-to-list-dropdown .list-item input {
  margin-right: 5px;
}
.add-to-list-dropdown .create-list-form {
  display: flex;
  margin-top: 10px;
}
.add-to-list-dropdown .create-list-form input {
  flex-grow: 1;
  margin-right: 5px;
}
.add-to-list-dropdown .create-list-form button {
  white-space: nowrap;
}
.add-to-list-dropdown .in-list {
  color: rgb(0, 255, 242);
}
.list-item.special-list {
  background-color: #0f2c32;
  border: 2px solid #007bff;
}
.list-item.special-list .list-item-header h3 {
  color: #007bff;
}
.dropdown-content .dropdown-header {
  padding: 10px;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.dropdown-content .create-list-form input {
  max-width: 100px;
}
.dropdown-content .create-list-form button {
  padding: 5px;
  margin: 0px;
  background: transparent;
  color: white;
  border: 1px solid white;
}
.list-page .list-header {
  background-color: var(--surface);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}
.list-page .list-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: var(--on-surface-medium);
}
.list-page .list-description {
  margin-bottom: 20px;
  font-style: italic;
}
.list-page .list-actions {
  display: flex;
  gap: 10px;
}
.list-page .list-actions button {
  background-color: var(--primary-color);
  color: var(--on-primary);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.list-page .list-actions button:hover {
  background-color: var(--primary-variant);
}
@media (max-width: 768px) {
  .list-item-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .list-item-actions {
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .list-item-stats {
    margin-top: 0.5rem;
  }
}