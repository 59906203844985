.share-dropdown {
  position: relative;
}
.share-dropdown-content {
  position: absolute;
  background-color: var(--surface);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  padding: 10px;
  z-index: 1001;
  min-width: 150px;
}
.share-dropdown-content button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  color: var(--on-surface);
  cursor: pointer;
}
.share-dropdown-content button:hover {
  background-color: var(--primary-variant);
}
.copy-link-button {
  font-weight: bold;
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .share-dropdown-content {
    right: 0;
  }
}