header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--surface);
  color: var(--on-surface);
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  width: 100%;
  display: flex;
  justify-content: center;
}
.header-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  height:40px;
  padding: 0.5rem;
}
.header-left {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}
.header-title {
  color: var(--header-text);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}
.header-logo {
  width: 150px;
  height: auto;
  margin-top:-10px;
}
.header-right {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
header a {
  color: white;
}
header form {
  flex-direction: row;
}
.nav-link {
  color: var(--on-surface);
  text-decoration: none;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
.nav-link:hover {
  background-color: var(--primary-variant);
}
.search-container {
  flex-grow: 0.1;
  margin: 0 1rem;
}
.search-container input {
  background-color: var(--surface);
  color: var(--on-surface);
  border-color: var(--secondary-color);
}
.search-filter-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  background-color: var(--surface);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}
.search-filter-form select, .search-filter-form input, .search-filter-form button {
  padding: 0.5rem;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  background-color: var(--background);
  color: var(--on-surface);
}
.search-filter-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.search-filter-form input[type="date"], .search-filter-form .tags-input, .search-filter-form .sort-by {
  padding: 0.5rem;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  background-color: var(--background);
  color: var(--on-surface);
  width:100px;
}
.search-filter-form button {
  background-color: var(--secondary-color);
  color: black;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.search-filter-form button:hover {
  background-color: var(--primary-variant);
}
.search-filter-form .tags-input-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.search-filter-form .filter-element {
  margin-bottom: 0.5rem;
}
.search-bar-container {
  position: relative;
}
.search-form {
  display: flex;
  align-items: center;
  gap:0px;
}
.search-form button{
  height:37px;
}
.search-form input {
  color:white;
  border: 1px solid var(--secondary-color);
  height:20px;
  background-color:rgb(23, 22, 22);
}
.search-filter-button {
  padding: 0.5rem;
  border-radius: 4px 0 0 4px;
  border: none;
  background: var(--secondary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid var(--secondary-color);
  border-left: none;
  border-right: none;
}
.search-submit-button {
  padding: 0.5rem;
  border-radius: 0 4px 4px 0;
  border: none;
  background: var(--secondary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-row {
  display: flex;
  gap: 1rem;
}
.user-menu {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.user-menu button {
  background: none;
  border: none;
  color: var(--on-surface);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.user-controls {
  display: flex;
  align-items: center;
}
.user-menu span {
  margin-right: 0.5rem;
}
.user-dropdown {
  position: absolute;
  min-width: 150px;
  top: 100%;
  right: 0;
  background-color: var(--surface);
  border-color: var(--primary-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
}
.user-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.user-dropdown li {
  padding: 10px;
  text-align: left;
}
.user-dropdown li:hover {
  background-color: var(--secondary-color);
}
.user-dropdown li button:hover {
  background-color: var(--secondary-color);
}
.user-dropdown a, .user-dropdown button {
  text-decoration: none;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  width: 100%;
  text-align: left;
  font-size: 1em;
  padding: 0;
}
.category-dropdown, .discover-dropdown {
  position: relative;
  display: inline-block;
}
.category-dropdown-toggle, .discover-dropdown-toggle {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--surface);
  border-color: var(--primary-color);
}
.category-dropdown-toggle svg, .discover-dropdown-toggle {
  margin-left: 5px;
}
.category-dropdown-menu, .discover-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--surface);
  border-color: var(--primary-color);
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  min-width: 200px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.category-dropdown-menu li, .discover-dropdown-menu li {
  padding: 10px;
}
.category-dropdown-menu li:hover, .discover-dropdown-menu li:hover {
  background-color: var(--secondary-color);
}
.category-dropdown-menu a,.discover -dropdown-menu a {
  color: var(--text-color);
  text-decoration: none;
  display: block;
}
.hamburger-menu {
  display: none;
}
.mobile-search-icon,
.mobile-search {
  display: none;
}
.header .nav-link a, .header button, .nav-link{
  font-size:1rem;
  font-weight:600;
}
.header-username{
  margin-left:5px;
}
.header .nav-link:hover {
  cursor: pointer;
}
.mobile-controls {
  display: flex;
  align-items: center;
}
.mobile-menu {
  display:flex;
  flex-direction:row;
  align-items:center;
}
@media (max-width: 768px) {
  .header {
    height: 60px;
  }
  .header-logo {
    height: 45px;
    width: auto;
    margin-top: 0px;
  }
  .header-container {
    justify-content: space-between;
    padding: 10px 15px;
  }
  .hamburger-menu,
  .mobile-search-icon {
    display: block;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--on-surface);
    cursor: pointer;
    padding: 8px;
    margin-left: 10px;
  }
  .hamburger-menu {
    margin-left: 15px;
  }
  .mobile-menu {
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    top: 60px;
    right: -100%;
    width: 80%;
    height: calc(100% - 60px);
    background-color: var(--surface);
    transition: right 0.3s ease-in-out;
    overflow-y: auto;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  }
  .desktop-search {
    display: none;
  }
  .mobile-menu.open {
    right: 0;
  }
  .mobile-menu .mobile-search {
    margin-bottom: 25px;
  }
  .mobile-menu .user-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .mobile-menu .user-dropdown{
    width:100%;
  }
  .mobile-menu .user-dropdown li {
    padding: 10px;
  }
  .mobile-menu .discover-dropdown {
    width: 100%;
  }
  .mobile-menu .nav-link,
  .mobile-menu .btn,
  .mobile-menu .user-menu button {
    margin: 8px 0;
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    border-radius: 8px;
    background-color: var(--surface-variant);
    transition: background-color 0.2s ease;
  }
  .mobile-menu .nav-link:hover,
  .mobile-menu .btn:hover,
  .mobile-menu .user-menu button:hover {
    background-color: var(--primary-variant);
  }
  .search-container {
    width: 100%;
    margin: 15px 0;
  }
  .user-menu {
    width: 100%;
  }
  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .mobile-search-icon,
  .mobile-search {
    display: block;
  }
  .mobile-menu .user-menu button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-menu .user-menu button .header-username {
    margin-left: 8px;
  }
  .mobile-menu .discover-dropdown-toggle {
    width: 100%;
    display:flex;
    justify-content:center;
    text-align: center;
    padding: 12px;
    margin-left:8px;
    font-size: 1.1rem;
    border-radius: 8px;
    background-color: var(--surface-variant);
  }
  .mobile-menu .discover-dropdown-menu {
    position: static;
    width: 100%;
    margin-top: 5px;
    border-radius: 8px;
    box-shadow: none;
    display:flex;
    flex-direction:column;
    align-items:center;
  }
  .mobile-menu .discover-dropdown-menu li {
    padding: 10px;
  }
  .mobile-menu .discover-dropdown-menu a {
    padding: 12px;
    text-align: center;
    font-size: 1rem;
    border-radius: 8px;
    transition: background-color 0.2s ease;
  }
  .mobile-menu .discover-dropdown-menu a:hover {
    background-color: var(--primary-variant);
  }
}