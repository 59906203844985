.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background-color: var(--background);
  padding: 2rem;
  border-radius: 8px;
  max-width: 1000px;
  overflow-y: auto;
  max-height: 90vh;
  color: white;
}

.modal-content h2, .modal-content h3, .modal-content p, .modal-content label {
  color: white;
}
.modal-content input, .modal-content select {
  color:black;
}
.modal-content form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.modal-content input, .modal-content select, .modal-content button {
  padding: 10px;
  font-size: 16px;
}
.modal-content img {
  max-width: 100%;
  height: auto;
}
.modal-content .tabs {
  display:flex;
  gap:20px;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}
.modal-header h2 {
  margin-bottom: 0px;
}
.modal-content::-webkit-scrollbar {
  width: 10px;
}
.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
.modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.suggesting-indicator {
  color: #007bff;
  font-style: italic;
}
.suggestion {
  color: #28a745;
  font-size: 0.9em;
  margin-top: 5px;
}
.toggle-video-add-button{
  background:transparent;
  text-decoration:underline;
  color: #007bff;
}
.toggle-video-add-button:hover{
  background-color: black;
}
.review-toggle {
  margin-top: 10px;
}
.review-toggle label {
  display: flex;
  align-items: center;
}
.review-toggle input[type="checkbox"] {
  margin-right: 5px;
}
textarea[name="review"] {
  width: 100%;
  min-height: 100px;
  margin-top: 10px;
  padding: 5px;
}
.user-action-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.user-action-modal .modal-content {
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 90%;
}
@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    padding: 1rem;
  }
}