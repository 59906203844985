.user-profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}
.user-profile h3{
  max-width:200px;
}
.user-profile form {
  margin-bottom: 20px;
  max-width:200px;
}
.user-profile label {
  display: block;
  margin-bottom: 5px;
}
.user-profile input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}
.user-profile button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.user-profile button:hover {
  background-color: var(--primary-color-dark);
}
.user-reviews {
  list-style-type: none;
  padding: 0;
}
.user-reviews li {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
.user-reviews a{
  color:rgb(29, 138, 165);
}
.message {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .user-profile {
    padding: 10px;
  }
  .user-profile form {
    max-width: 100%;
  }
}