.contact-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}
  
.contact-page .contact-form {
display: flex;
flex-direction: column;
gap: 20px;
}

.contact-page .form-group {
display: flex;
flex-direction: column;
}

.contact-page .form-group label {
margin-bottom: 5px;
}

.contact-page .form-group input,
.contact-page .form-group textarea {
padding: 10px;
border: 1px solid var(--primary-color);
border-radius: 4px;
background-color: var(--surface);
color: var(--on-surface);
}

.contact-page .form-group textarea {
min-height: 150px;
}

.contact-page .error-message {
color: #ff0000;
font-size: 0.9em;
margin-top: 5px;
}

.contact-page .success-message {
color: #00ff00;
font-size: 1em;
margin-bottom: 20px;
}

.contact-page .submit-button {
align-self: flex-start;
padding: 10px 20px;
background-color: var(--primary-color);
color: var(--on-primary);
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s ease;
}

.contact-page .submit-button:hover {
background-color: var(--primary-variant);
}