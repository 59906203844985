.rating-component {
  display: flex;
  align-items: center;
}
.stars {
  display: inline-flex;
  margin-right: 5px;
}
.star {
  color: #e4e5e9;
}
.star-svg {
  margin-right: 5px;
}
.star.filled {
  color: #ffc107;
}
.rating-text {
  font-weight: bold;
}
.rating-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.rating {
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
}
.rating input[type="radio"] {
  display: none;
}
.rating-number {
  font-size: 18px;
  font-weight: bold;
  min-width: 20px;
}
.user-reviewed-indicator {
  color: #007bff;
  margin-left: 2px;
  font-size: 0.8em;
  vertical-align: super;
  z-index:1000;
}
@media (max-width: 768px) {
  .rating-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .rating {
    margin-bottom: 10px;
  }
}