@import './admin.css';
@import './contact.css';
@import './header.css';
@import './lists.css';
@import './modal.css';
@import './rating.css';
@import './search.css';
@import './share.css';
@import './userProfile.css';
@import './video.css';
@import 'rc-slider/assets/index.css';

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap'); */

:root {
  --primary-color: #bb86fc;
  --primary-variant: #3700b3;
  --secondary-color: rgb(21, 149, 136);
  --background: #2b2a2a;
  --surface: #1e1e1e;
  --error: #cf6679;
  --on-primary: #1b1a1a;
  --on-secondary: #000000;
  --on-background: #ffffff;
  --on-surface: #ffffff;
  --on-error: #000000;
  --rating-color: #ffd700;
}
h1 {
  text-align:center;
}
h2 {
  margin-top:7px;
  margin-bottom:7px;
}
 body {
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  /* font-family: 'Nunito', sans-serif; */
  font-family: 'Raleway', sans-serif;
  /* font-family: 'Source Sans Pro', sans-serif; */
  /* font-family: 'Inter', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  background-color: var(--background);
  color: var(--on-background);
  line-height: 1.6;
  margin:0px;
}
.page-content{
  max-width:1400px;
  padding:20px;
  margin: 0 auto;
}
.btn {
  margin-right: 1rem;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
button {
  background-color: var(--secondary-color);
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
}
.btn-primary {
  width: 100%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.btn-primary:hover {
  background-color: var(--primary-variant);
}
button:hover {
  background-color: #21a1c9;
}
.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
  font-weight:bold;
}
.btn-secondary:hover {
  background-color: #27ae60;
}
a{
  color:#03dac6;
  text-decoration:none;
}
p{
  margin:0px;
}
h4{
  padding:0px;
  margin:0px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.form-control {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  background-color: var(--surface);
  color: var(--on-surface);
  border-color: var(--primary-color);
}
input {
  padding: 10px;
  font-size: 16px;
  /* max-width:250px; */
}
form button {
  max-width: 250px;
}
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.error-message {
  color: red;
  margin-top: 10px;
}
.close-button {
  background-color: #f44336;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
}
.close-button:hover {
  background-color: #d32f2f;
}
.modal-content .close-button{
  background-color: var(--primary-color);
  height:25px;
  width:25px;
  font-size:15px;
  padding:0px;
}
.video-form{
  margin-top:10px;
  margin-bottom:10px;
  display: flex;
  flex-direction:row;
  gap:10px;
}
.video-form button {
  height:40px;
}
.video-form .video-form-title{
  max-width:200px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;


}
.add-video-form {
  display: flex;
  flex-direction: column;
}
.bulk-video-headers {
  display: flex;
  font-weight: bold;
  margin-bottom: 10px;
}

.bulk-video-headers > div {
  flex: 1;
}



.form-input {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.carousel-container {
  margin-bottom: 1rem;
  padding:0.5rem;
  background-color: #1e1e1e; 
  border-radius: 10px;
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.5), 
              inset 0 -4px 6px rgba(255, 255, 255, 0.1);
}
.carousel-content {
  display: flex;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  max-height:90%;
}
.carousel-item {
  padding:5px;
  flex: 0 0 auto;
  width: 250px;
  margin-right: 1rem;
  background-color: #141313;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7); 
  transition: transform 0.2s ease, box-shadow 0.2s ease; 
}
.carousel-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9);
}
.user-lists-page .list-item:hover {
  transform: none;
  box-shadow: none;
}
.user-lists-page .tabs {
  display:flex;
  gap:20px;
  flex-wrap: wrap;
  padding-bottom:10px;
}
.list-browsing-page .carousel-item:hover {
  transform: none;
  box-shadow: none;
}
.carousel-item p {
  color: white;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 6px;
  position: relative;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  background-clip: padding-box;
  border: 3px solid transparent;
  height: 50%;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
::-webkit-scrollbar:horizontal {
  height: 12px;
}
::-webkit-scrollbar-track:horizontal {
  background-color: #bbbbbb49;
  border-radius: 6px;
  position: relative;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--secondary-color);
  border-radius: 12px;
  border: 3px solid transparent;
  min-height: 6px;
  width: 50%;
}
::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: var(--primary-variant);
}
.registration-prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.registration-prompt-modal {
  position: relative;
  background: var(--background);
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}
.registration-prompt-modal form {
  display:flex;
  flex-direction:column;
}
.btn.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.toggle-link-container {
  text-align: center;
  margin-top: 20px;
}
.toggle-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
.toggle-link:hover {
    color: #0056b3;
}
.login-register-page {
    max-width: 800px;
    margin: 0 auto;
}
.composite-image {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}
.composite-image-quadrant {
  position: absolute;
  width: 50%;
  height: 50%;
  overflow: hidden;
}
.composite-image-quadrant:nth-child(1) {
  top: 0;
  left: 0;
}
.composite-image-quadrant:nth-child(2) {
  top: 0;
  right: 0;
}
.composite-image-quadrant:nth-child(3) {
  bottom: 0;
  left: 0;
}
.composite-image-quadrant:nth-child(4) {
  bottom: 0;
  right: 0;
}
.composite-image-quadrant img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.no-thumbnails {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #666;
}
.hero-section {
  height: 250px;
  background: #04475070;
  color: var(--on-background);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}
.hero-section h1 {
  margin: 0;
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
.hero-section p {
  margin-top: 10px;
  font-size: 1.5rem;
  color: #f0f0f0;
}
.hero-buttons {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}
.hero-buttons .btn-primary {
  padding: 10px 20px;
  background-color: var(--secondary-color);
  color: var(--on-primary);
  border-radius: 25px;
  font-size: 1rem;
  color:white;
  font-weight:bold;
  transition: transform 0.3s ease;
}
.hero-buttons .btn-primary:hover {
  transform: scale(1.05);
  background-color: var(--secondary-color);
}
.hero-buttons .btn-secondary {
  padding: 10px 20px;
  background-color: var(--secondary-color);
  color: var(--on-secondary);
  border-radius: 25px;
  font-size: 1rem;
  font-weight:bold;
  transition: transform 0.3s ease;
}
.hero-buttons .btn-secondary:hover {
  transform: scale(1.05);
  background-color: #27ae60;
}
.footer {
  background-color: var(--surface);
  color: var(--on-surface);
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid #444;
}
.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}
.footer-section {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}
.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}
.footer-section p {
  font-size: 0.9rem;
  line-height: 1.6;
}
.footer-section ul {
  list-style-type: none;
  padding: 0;
}
.footer-section ul li {
  margin: 5px 0;
}
.footer-section ul li a {
  color: var(--on-surface);
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-section ul li a:hover {
  color: var(--primary-color);
}
.social-icons {
  margin-top: 10px;
}
.social-icons a {
  color: var(--on-surface);
  margin: 0 10px;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}
.social-icons a:hover {
  color: var(--primary-color);
}
.footer-bottom {
  margin-top: 20px;
  font-size: 0.8rem;
}
.discover-section {
  background-color: #1e1e1e;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  display:flex;
  flex-direction:column;
  gap:30px;
}
.discover-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}
.discover-content, .content-quality {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  max-width: 1200px;
  margin: 0 auto;
}
.discover-text, .quality-text {
  flex: 1;
  min-width: 300px;
  margin: 20px;
  text-align:left;
}
.discover-image, .quality-image {
  flex: 1;
  min-width: 300px;
  margin: 20px;
  max-height:200px;
  overflow:hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.discover-image img, .quality-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.discover-text h3, .quality-text h3 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}
.discover-text ul, .quality-text ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}
.discover-text li, .quality-text li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}
.add-channel-section {
  background-color: #333;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  max-width:600px;
  margin: 0 auto;
}
.add-channel-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.add-channel-button {
  color:white;
  font-weight: bold;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
}
.similarity-score{
  bottom: 20px;
  right: 5px;
  position:absolute;
}
.review-modal-overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.review-modal-content{
  background-color: #333;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  max-width:600px;
  margin: 0 auto;
}
.youtube-search .search-results-table {
  width: 100%;
  border-collapse: collapse;
}
.youtube-search .search-results-table th,
.youtube-search .search-results-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.youtube-search .search-results-table .thumbnail-col { width: 15%; }
.youtube-search .search-results-table .title-col { width: 30%; }
.youtube-search .search-results-table .channel-col { width: 20%; }
.youtube-search .search-results-table .views-col { width: 5%; }
.youtube-search .search-results-table .duration-col { width: 5%; }
.youtube-search .search-results-table .exists-col { width: 14%; }
.youtube-search .search-results-table .status-col { width: 7%; }
.youtube-search .search-results-table .action-col { width: 7%; }

.search-results-table .selected-row {
  background-color: #1f8124b2;
}
.search-results-table a{
  color: var(--primary-color);
}
.similarity-info{
  margin-top:5px;
  margin-bottom:5px;
}
.category-selection{
  display:flex;
  flex-direction:column;
}
.subcategory-selection{
  display:flex;
  flex-direction:column;
}
.category-selection .category,
.subcategory-selection .subcategory{
  margin-right:10px;
}
.category-selection select,
.subcategory-selection select{
  width:150px;
}
.category-selection .category input,
.subcategory-selection .subcategory input{
  margin-right:10px;
}
.category-selection .category select,
.subcategory-selection .subcategory select{
  margin-right:10px;
}
.category-selection .category button,
.subcategory-selection .subcategory button{
  margin-right:10px;
}
.category-selection .category button{
  margin-right:10px;
}
.category-selection .category button{
  margin-right:10px;
}
.category-selection .category button{
  margin-right:10px;
}
.category-selection .category button{
  margin-right:10px;
}
.category-selection .category button{
  margin-right:10px;
}
.quick-add-feedback a {
  color: var(--primary-color);
}
.watched-filter{
  margin-top:10px;
}
@media (max-width: 768px) {
  .btn {
    padding: 8px 12px;
    font-size: 14px;
  }
  input, select, textarea {
    font-size: 16px;
  }
  .hero-section h1{
    font-size:2rem;
   }
  .discover-image, .quality-image{
    display:none;
  }
  .search-thumbnail, .thumbnail-col{
    display:none;
  }
  .search-duration, .duration-col{
    display:none;
  }
  .video-form{
    flex-direction:column;
    text-align:center;
    justify-content:center;
    align-items:center;
  }
  .video-form .video-form-title{
    min-width:190px;
    max-width:800px;
    text-align:center;
  }
  .video-form .category-selection{
    width:90%;
  }
  .video-form .category-selection select{
    width:90%;
  }
  .video-form .tag-filter {
    width: 90%;
  }
  .video-form .tag-input-container {
    width: 90%;
  }
  .video-form .tag-filter input{
    width:100%;
  }
  .video-form .video-remove-button{
    display:none;
  }
  .mobile-more-filters .tag-filter{
    flex-direction:row;
    width:90%;
    margin-top:10px;
    justify-content:center;
  }
  .mobile-more-filters .tag-filter .tag-input-container{
    flex-direction:row;
    width:90%;
    height:auto;
    justify-content:flex-start;
  }
  .mobile-more-filters .date-range-filter label {
    display:block;
    margin-bottom:10px;
  }
  .mobile-more-filters .date-picker-container {
    flex-direction:row;
    justify-content:space-around;
    gap:20px;
  }
  .mobile-more-filters .date-picker-container .react-datepicker-wrapper {
    max-width:40%;
  }
  .mobile-more-filters .sort-options {
    display:flex;
    flex-direction:column;
    gap:10px;
  }
  .mobile-more-filters .sort-options label{
    display:block;
    gap:10px;
    width:37%;
  }
  .mobile-more-filters .sort-options select{
    display:block;
    gap:10px;
    width:60%;
  }
  .primary-sort-section, .secondary-sort-section{
    display:flex;
    flex-direction:row;
  }
  .mobile-more-filters .watched-filter {
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    width:90%;
  }
  .search-sidebar-filter .watched-filter label {
    margin-top:10px;
    width:90%;
  }
  .search-sidebar-filter .watched-filter input {
    width:5%;
  }
  .mobile-more-filters .slider-container {
    margin-top:10px;
  }
  .mobile-more-filters .slider-container label {
    display:block;
    margin-bottom:5px;
    margin-left:-5px;
  }
}