.admin-panel {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.admin-tabs {
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  gap: 10px;
}
.admin-tabs button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.admin-tabs button:hover {
  background-color: #e0e0e0;
}
.section-header {
  display: grid;
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid #ccc;
}
.section-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.section-list li {
  display: grid;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.section-list li button {
  padding: 5px 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.section-list li button:hover {
  background-color: #ff1a1a;
}
.users-section .section-header, .users-section .section-list li {
  grid-template-columns: 2fr 2fr 1fr 1fr 1fr 2fr 1fr;
  gap: 10px;
}
.videos-section .section-header, .videos-section .section-list li {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 5px;
  align-items: center;
}
.videos-section select {
  width: 100%;
  padding: 5px;
  font-size: 0.9em;
}
.videos-section select[multiple] {
  height: 60px;
  overflow-y: auto;
}
.videos-section .section-list li span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.videos-section .section-list li a {
  color: #0066cc;
  text-decoration: none;
}
.videos-section .section-list li a:hover {
  text-decoration: underline;
}
.reviews-section .section-header, .reviews-section .section-list li {
  grid-template-columns: 2fr 2fr 1fr 3fr 1fr 2fr 1fr;
  gap: 10px;
}
.lists-section .section-header, .lists-section .section-list li {
  grid-template-columns: 2fr 2fr 3fr 1fr 1fr 1fr 2fr 1fr;
  gap: 10px;
}
.form-section {
  margin-bottom: 20px;
}
.form-section input {
  margin-right: 10px;
  padding: 5px;
  width: calc(100% - 20px);
  margin-bottom: 10px;
}
.form-section button {
  width: 100%;
  padding: 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.form-section button:hover {
  background-color: #45a049;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.pagination button:hover {
  background-color: #e0e0e0;
}
.pagination button.active {
  background-color: #4CAF50;
  color: white;
}
.tags-section .section-header, .tags-section .section-list li {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;
  align-items: center;
}
.categories-section .section-header, .categories-section .section-list li {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  gap: 10px;
  align-items: center;
}
.subcategories-section .section-header, .subcategories-section .section-list li {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  gap: 10px;
  align-items: center;
}
.section-header, .section-list li {
  display: grid;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.section-header {
  font-weight: bold;
  border-bottom: 2px solid #ccc;
}
.section-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.section-list li span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-actions-section table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.user-actions-section th, .user-actions-section td {
  padding: 10px;
  border: 1px solid #e0e0e0;
  text-align: left;
}
.user-actions-section th {
  font-weight: bold;
}
.user-actions-section button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.user-actions-section button:hover {
  background-color: #45a049;
}
.user-actions-section form {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.user-actions-section form h3 {
  margin-top: 0;
  margin-bottom: 15px;
}
.user-actions-section form label {
  display: block;
  margin-bottom: 5px;
}
.user-actions-section form select, .user-actions-section form textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.user-actions-section form textarea {
  height: 100px;
  resize: vertical;
}
.user-actions-section form button {
  padding: 8px 15px;
  margin-right: 10px;
}
.user-actions-section form button[type="submit"] {
  background-color: #4CAF50;
}
.user-actions-section form button[type="button"] {
  background-color: #f44336;
}
.cron-jobs-section {
  display:flex;
  flex-direction:column;
}
.cron-jobs-section button{
  max-width:300px;
  margin:5px;
}
.endpoint-url-wrapper {
  max-width: 300px;
  overflow-x: auto;
}
.endpoint-stats-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.endpoint-stats-table th, 
.endpoint-stats-table td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}
.endpoint-stats-table th {
  font-weight: bold;
}
.method-stats-table {
  width: 100%;
  border-collapse: collapse;
}
.method-stats-table th,
.method-stats-table td {
  padding: 5px;
  border: 1px solid #eee;
  text-align: left;
  font-size: 0.9em;
}
.endpoint-stats-table th {
  cursor: pointer;
}
.admin-panel td:hover{
  background-color: black;
}
.admin-panel h4 {
  font-size:1.5rem;
  margin-bottom:10px;
  margin-top:20px;
  border-top: 1px solid white;
}
.api-stats {
  display:flex;
  flex-direction:row;
}

@media (max-width: 768px) {
  .user-actions-section table, .user-actions-section thead, .user-actions-section tbody, .user-actions-section th, .user-actions-section td, .user-actions-section tr {
    display: block;
  }
  .user-actions-section thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .user-actions-section tr {
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  .user-actions-section td {
    border: none;
    position: relative;
    padding-left: 50%;
  }
  .user-actions-section td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    font-weight: bold;
  }
  .endpoint-stats-table,
  .method-stats-table {
    font-size: 0.8em;
  }
}