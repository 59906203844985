.search-container {
  display: flex;
  gap: 20px;
}
.search-sidebar-filter {
  width: 250px;
  padding: 20px;
  border-radius: 5px;
}
.search-results {
  flex-grow: 1;
}
.search-term-input{
  margin-bottom:10px;
  width:80%;
}
.video-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}
.pagination li {
  margin: 0 5px;
}
.pagination a {
  padding: 5px 10px;
  border: 1px solid #ddd;
  color: white;
  text-decoration: none;
}
.pagination a:hover {
  background-color: #f5f5f5;
}
.pagination .active a {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}
.slider-container{
  height:60px;
  max-width:300px;
}
.date-range-filter {
  margin-top:10px;
  margin-bottom: 20px;
}
.date-picker-container {
  display: flex;
  justify-content: space-between;
}
.date-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.react-datepicker-wrapper {
  width: 48%;
}
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__year-dropdown {
  height: 300px;
  overflow-y: auto;
}
.expandable-list {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}
.expandable-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.expandable-list-header h4 {
  margin: 0;
}
.expandable-list-content {
  padding: 10px;
}
.tag-filter {
  display:flex;
  /* align-items:center; */
  position: relative;
  gap:10px;
  /* margin-top:10px; */
  /* margin-bottom:10px; */
  flex-direction:column;
  justify-content:flex-start;
 }
.tag-input-container {
  align-items: center;
  color:white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height:60px;
  margin-bottom:10px;
}
.tag-input-container input {
  color:white;
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
  background:transparent;
}

.tag-input-container input::placeholder {
  color: white;
}
.tag-pill {
  display: inline-flex;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 16px;
  padding: 2px 8px;
  margin: 2px;
  font-size: 14px;
}
.tag-pill button {
  background: none;
  border: none;
  color: #c5c52f;
  cursor: pointer;
  font-size: 16px;
  margin-left: 4px;
  padding: 0;
}
.video-form .tag-input-container .tag-pill{
  height:20px;
  padding:5px;
}
.tag-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  border: 1px solid #ccc;
  border-top: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}
.tag-suggestions li {
  padding: 8px;
  cursor: pointer;
}
.tag-suggestions li:hover {
  background-color: var(--primary-color);
}
.reset-filters-button {
  margin-top: 20px;
  padding: 8px 12px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}
.reset-filters-button:hover {
  background-color: #d32f2f;
}
.preconfigured-search {
  margin-bottom: 20px;
}
.preconfigured-search label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.preconfigured-search select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.total-videos-count {
  text-align:center;
}
@media (max-width: 768px) {
  .mobile-categories .expandable-list {
    width:100%;
  }
  .search-container {
    flex-direction: column;
  }
  .mobile-more-filters-toggle {
    max-width:80%;
  }
  
  .search-sidebar-filter {
    width: 100%;
    padding: 10px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-content:center;
    align-items:center;
  }
  .video-list {
    grid-template-columns: 1fr;
  }
  .date-picker-container {
    flex-direction: column;
  }
  .react-datepicker-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
  .mobile-filter-header {
    display: flex;
    flex-direction:row;
    align-items:center;
    margin-bottom: 10px;
    font-weight:600;
    gap:20px;
  }
  .filter-icon {
    font-size: 24px;
    cursor: pointer;
  }
  .mobile-quick-search {
    width: 100%;
  }
  .mobile-categories {
    width: 90%;
    margin-bottom: 10px;
  }
  .mobile-more-filters {
    width: 100%;
    text-align: center;
  }
  .mobile-more-filters-content {
    display: none;
  }
  .mobile-more-filters-content.expanded {
    display: flex;
    flex-direction:column;
    align-items:center;
    width:100%;
  }
  .mobile-more-filters-content.expanded .slider-container{
    width:90%;
  }
  .mobile-more-filters-toggle {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
  }
  .mobile-more-filters-toggle:after {
    content: '\25BC';
    float: right;
  }
  .mobile-more-filters-toggle.expanded:after {
    content: '\25B2';
  }
  .checkbox-list {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
  .checkbox-item {
    padding: 12px 10px;
  }
  .checkbox-item input[type="checkbox"] {
    width: 24px;
    height: 24px;
  }
  .checkbox-item label {
    font-size: 18px;
    padding: 10px 0;
  }
  .checkbox-item input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #2c2c2c;
    border: 2px solid #4a4a4a;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
  }
  .checkbox-item input[type="checkbox"]:checked {
    background-color: #3498db;
    border-color: #3498db;
  }
  .checkbox-item input[type="checkbox"]:checked::after {
    content: '\2714';
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
}