.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.video-card {
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
}
.video-thumbnail-container {
  position: relative;
}
.video-card{
  height: 240px;
  padding:5px;
}
.video-card .video-title a {
  font-size: 0.9rem;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  color: var(--on-surface);
  height:50px;
}
.video-bottom-row{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-top:0px;
}
.video-bottom-row button {
  padding:5px;
}
.video-views-overlay, .video-publish-overlay, .video-duration-overlay, .video-watched-checkbox-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.8rem;
  font-weight: bold;
  z-index: 1;
}
.video-watched-checkbox-overlay{
  display: flex;
  align-items: center;
  cursor:pointer;
}
.video-card .video-watched-checkbox-overlay {
  position: absolute;
  top: 5px;
  left: 5px;
}
.video-page .video-watched-checkbox-overlay{
  width: 100px;
  position:relative;
  background-color:transparent;
}
.video-views-overlay {
  bottom: 5px;
  right: 5px;
}
.video-duration-overlay{
  bottom: 5px;
  left: 5px;
}
.video-publish-overlay {
  top:5px;
  right:5px;
}
.video-thumbnail {
  width: 100%;
  height: 112px;
  object-fit: cover;
}
.video-rating {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: 10px;
}
.video-category a{
  color:green;
}
.video-metadata {
  padding: 20px;
  background-color: var(--surface);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.video-rating span {
  color: var(--rating-color);
  font-weight: bold;
}
.video-card .video-channel {
  height: 30px;
}
.video-channel, .video-views, .video-publish-date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  overflow:hidden;
  white-space:pre;
  text-overflow: ellipsis;
}
.video-avgRating {
  margin-right:5px;
}
.video-weightedRating {
  margin-left:5px;
}
.video-channel a{
  color: var(--primary-color);
}
.view-more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-more a {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
}
.video-info {
  flex: 1;
  padding-right:10px;
  padding-left:10px;
  display: flex;
  flex-direction: column;
  color: black;
}
.video-title {
  font-size: 18px;
  margin-bottom:0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--on-surface);
  font-size: 0.9rem;
}
.video-title a{
  color: var(--on-surface);
  font-size:20px;
}
.video-description {
  max-height: 100px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}
.video-description-container {
  margin-top: 10px;
}
.video-description.expanded {
  max-height: none;
}
.toggle-description {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 0;
  font-size: 0.9em;
  margin-top: 5px;
}
.toggle-description:hover {
  text-decoration: underline;
}
.video-list {
  margin-top: 20px;
}
.watched-checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
}
.existing-video-container {
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}
.existing-video-container .video-card{
  height:400px;
}
.existing-video-container .video-title a, .existing-video-container .video-info a {
  color: #0066cc;
  text-decoration: none;
}
.existing-video-container .video-title a:hover, .existing-video-container .video-info a:hover {
  text-decoration: underline;
}
.category-link {
  text-decoration: none;
  font-weight: bold;
}
.category-link:hover {
  text-decoration: underline;
}
.tag {
  background-color: var(--secondary-color);
  color: var(--on-primary);
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.8rem;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
}
.tags {
  margin-top: 10px;
  margin-bottom:10px;
}
.tag-link {
  color: white;
  display: inline-block;
  margin-right: 10px;
  padding: 2px 10px;
  background-color: var(--secondary-color);
  text-decoration: none;
  border-radius: 15px;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}
.tag-link:hover {
  background-color: var(--primary-color);
}
.tag-link svg {
  margin-right: 5px;
}
.review-form {
  margin-bottom: 20px;
}
.review-form .rating {
  display: flex;
  justify-content: flex-start;
}
.review-form textarea {
  width: 100%;
  margin-bottom: 10px;
}
.reviews {
  margin-top: 30px;
  margin-bottom: 30px;
}
.review {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.review-item{
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  gap:20px;
}
.review-header{
  display:flex;
  justify-content:flex-start;
  gap:20px;
}
.review-footer button {
  background:transparent;
  padding: 0;
}
.review-footer button:hover {
  color:green;
  padding: 0;
  background:transparent;
}
.video-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.video-actions {
  display: flex;
  gap: 15px;
}
.video-channel-category {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}
.video-channel-category a {
  font-size: 0.9rem;
  text-decoration: none;
}
.video-card a:hover {
  text-decoration: underline;
}
.video-card .video-channel {
  color: var(--primary-color);
}
.video-card .video-category {
  color: var(--secondary-color);
}
.video-channel-category .category-link {
  font-weight: bold;
  margin-left: auto;
}
.video-channel-category .category-link:hover {
  text-decoration: underline;
}
.add-to-list-button, .login-to-review-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}
.add-to-list-button:hover, .login-to-review-button:hover {
  background-color: var(--primary-variant);
}
.video-card .channel-link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.channel-link, .category-link, .subcategory-link, .tag-link {
  text-decoration: none;
}
.channel-link:hover, .category-link:hover, .subcategory-link:hover {
  text-decoration: underline;
  color: var(--primary-color)
}
.video-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.video-container{
  display: flex;
  justify-content:center;
}
.video-frame {
  width:50%;
  height:450px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.video-details {
  display: flex;
  justify-content: flex-start;
  gap:2rem;
  padding: 10px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
.rating-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.rating-number {
  margin-left: 10px;
  font-size: 1.2rem;
  color: #333;
}
.report-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
}
.report-button:hover {
  background-color: #d32f2f;
}
.video-list .video-card{
  padding:5px;
  flex: 0 0 auto;
  margin-right: 1rem;
  background-color: #141313;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7); 
  transition: transform 0.2s ease, box-shadow 0.2s ease; 
}
.bulk-add-form .video-form {
  display:flex;
  flex-direction:row;
  max-height:60px;
  margin-bottom: 10px;
}



/* Container for perspective */
.video-card-container {
  perspective: 1000px;
}

/* Main video card styling */
.video-card {
  /* width: 300px; */
  /* height: 400px; */
  position: relative;
  transform-style: preserve-3d;
  /* animation: float 3s ease-in-out infinite, rotateVertical 10s linear infinite; */
  transition: all 0.5s ease;
}

.video-card:hover {
  animation: pulse 1.5s infinite;
  transform: translateY(0) rotateY(0) !important;
}

/* Video card faces */
.video-card-face {
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: white;
  transition: all 0.5s ease;
  border-radius: 10px;
}

.video-card-front {
  background-color: #3498db;
}

.video-card-back {
  background-color: #e74c3c;
  transform: rotateY(180deg);
}

/* Animations */
@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes rotateVertical {
  0% { transform: rotateY(0); }
  100% { transform: rotateY(360deg); }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7); }
  50% { transform: scale(1.05); box-shadow: 0 0 0 10px rgba(52, 152, 219, 0); }
}

/* Hover effects */
.video-card-container:hover .video-card {
  animation: pulse 1.5s infinite;
  transform: translateY(0) rotateY(0) !important;
}

.video-card-container:hover .video-card-front {
  background-color: #2980b9;
}



@media (max-width: 768px) {
  .video-header {
    flex-direction: column;
    margin-bottom: 5px;
  }
  .video-grid {
    grid-template-columns: 1fr;
  }
  .video-card {
    height: auto;
  }
  .video-info {
    padding: 10px 0;
  }
  .video-actions {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .video-actions > * {
    margin-bottom: 10px;
  }
  .video-channel-category .category-link {
    margin-left: 0;
    margin-top: 5px;
  }
  .video-frame {
    width:100%;
    height:250px;
  }
}